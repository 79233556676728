import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

@Component({
  selector: 'mp-zendesk-feedback-button',
  standalone: true,
  templateUrl: './zendesk-feedback-button.component.html',
  styleUrls: ['./zendesk-feedback-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgClass, MatIconModule, MatDialogModule, MatLegacyButtonModule, MatLegacyTooltipModule],
})
export class ZendeskFeedbackButtonComponent {
  @Input({ required: true }) isSmall!: boolean;

  @Output() readonly openZendeskDialogClick: EventEmitter<void> = new EventEmitter<void>();

  openZendeskDialog(): void {
    this.openZendeskDialogClick.emit();
  }
}
