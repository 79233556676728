import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@Component({
  selector: 'mp-news-item-edit-actions',
  standalone: true,
  templateUrl: './news-item-edit-actions.component.html',
  styleUrls: ['./news-item-edit-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, MatButtonModule, MatIconModule, MatTooltipModule],
})
export class NewsItemEditActionsComponent {
  @HostBinding() readonly class = 'mp-news-item-edit-actions';

  @Input() disabled = false;

  @Input() tooltipText = '';

  @Input() showDeleteAction = false;

  @Output() readonly saveClick: EventEmitter<void> = new EventEmitter<void>();

  @Output() readonly closeClick: EventEmitter<void> = new EventEmitter<void>();

  @Output() readonly deleteClick: EventEmitter<void> = new EventEmitter<void>();

  onSave(): void {
    this.saveClick.emit();
  }

  onClose(): void {
    this.closeClick.emit();
  }

  onDelete(): void {
    this.deleteClick.emit();
  }
}
