import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ComponentStore } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/effects';
import { Observable, exhaustMap, filter, map, tap } from 'rxjs';

import { filterNull, filterUndefined } from '@core/shared/util';
import { AppConfigService } from '@mp/shared/data-access';
import { FeedbackFacade } from '@mp/shared/helper-links/feedback/data-access';
import { FeedbackSettings, FeedbackType, ZendeskFeedbackSettings } from '@mp/shared/helper-links/feedback/domain';
import {
  MailSenderDetails,
  SupportTicketFormData,
  ZendeskDialogIntegrationData,
  ZendeskSupportFormDialogComponent,
  ZendeskSupportFormDialogData,
} from '@mp/shared/helper-links/zendesk-support-dialog/ui';
import { UserInfoFacade } from '@mp/shared/profil/data-access';
import { ZendeskAuthFacade } from '@mp/shared/zendesk/auth-data-access';
import { ZendeskFacade } from '@mp/shared/zendesk/data-access';
import { ZendeskApiDetailsService } from '@mp/shared/zendesk/util';

@Injectable()
export class ZendeskSupportDialogContainerStore extends ComponentStore<Record<string, never>> {
  readonly mailSenderDetails$: Observable<MailSenderDetails> = this.userInfoFacade.profil$.pipe(
    map(({ vorname, nachname, email }) => ({
      userFirstName: vorname,
      userLastName: nachname,
      userEmail: email,
    })),
  );

  readonly feedbackSettings$: Observable<FeedbackSettings> =
    this.feedbackFacade.feedbackSettings$.pipe(filterUndefined());

  readonly zendeskDialogIntegrationData: ZendeskDialogIntegrationData = {
    sendTicket: (supportTicketFormData: SupportTicketFormData): void => {
      this.zendeskFacade.createTicket(supportTicketFormData);
    },
    ticketCreationSuccess$: this.zendeskFacade.ticketCreationSuccess$.pipe(map(() => true)),
    ticketCreationError$: this.zendeskFacade.ticketCreationError$.pipe(map(() => true)),
  };

  readonly zendeskClientId$: Observable<string | undefined> = this.appConfigService.envConfig$.pipe(
    map(({ zendeskClientId }) => zendeskClientId),
  );

  constructor(
    private readonly userInfoFacade: UserInfoFacade,
    private readonly zendeskFacade: ZendeskFacade,
    private readonly zendeskAuthFacade: ZendeskAuthFacade,
    private readonly appConfigService: AppConfigService,
    private readonly feedbackFacade: FeedbackFacade,
    private readonly zendeskApiDetailsService: ZendeskApiDetailsService,
    private readonly dialog: MatDialog,
  ) {
    super();

    this.initSetZendeskUrlEffect();
    this.initSetZendeskAccessTokenEffect();
    this.initZendeskAuthTokenResetEffect();
  }

  readonly openZendeskSupportDialog = this.effect((trigger$: Observable<void>): Observable<unknown> => {
    return trigger$.pipe(
      concatLatestFrom(() => this.zendeskClientId$),
      exhaustMap(([, zendeskClientId]) => this.zendeskAuthFacade.authorizeInZendesk(zendeskClientId)),
      filterNull(),
      concatLatestFrom(() => this.mailSenderDetails$),
      tap(([, mailSenderDetails]) => this.showZendeskSupportDialog(mailSenderDetails)),
    );
  });

  private showZendeskSupportDialog(mailSenderDetails: MailSenderDetails): void {
    this.dialog.open<ZendeskSupportFormDialogComponent, ZendeskSupportFormDialogData>(
      ZendeskSupportFormDialogComponent,
      {
        data: { ...this.zendeskDialogIntegrationData, mailSenderDetails },
        disableClose: true,
      },
    );
  }

  private initSetZendeskUrlEffect(): void {
    this.effect((feedbackSettings$: Observable<FeedbackSettings>) =>
      feedbackSettings$.pipe(
        filter(
          (feedbackSettings): feedbackSettings is ZendeskFeedbackSettings =>
            feedbackSettings.activeType === FeedbackType.Zendesk,
        ),
        tap(({ zendeskUrl }) => this.zendeskApiDetailsService.setZendeskApiUrl(zendeskUrl)),
      ),
    )(this.feedbackSettings$);
  }

  private initSetZendeskAccessTokenEffect(): void {
    this.effect((accessToken$: Observable<string | null>) =>
      accessToken$.pipe(tap((token) => this.zendeskApiDetailsService.setZendeskApiToken(token || ''))),
    )(this.zendeskAuthFacade.zendeskAccessToken$);
  }

  private initZendeskAuthTokenResetEffect(): void {
    this.effect((trigger$: Observable<unknown>) => trigger$.pipe(tap(() => this.zendeskAuthFacade.resetAuthToken())))(
      this.feedbackFacade.updateFeedbackSettingsSuccess$,
    );
  }
}
