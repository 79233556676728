import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';

import { AbstractModuleNamesService, ModuleNamesMap } from '@core/shared/util';
import { AppConfigService } from '@mp/shared/data-access';

import { navigationStructure } from '../navigation';

@Injectable()
export class AppModuleNamesService implements AbstractModuleNamesService {
  readonly homeModuleName$: Observable<string> = this.appConfigService.envConfig$.pipe(
    map(({ appTitle }) => appTitle),
    shareReplay(1),
  );

  readonly appModuleNames$: Observable<ModuleNamesMap> = this.homeModuleName$.pipe(
    map((homeModuleName) => this.createAppModuleNamesMap(homeModuleName)),
    shareReplay(1),
  );

  private readonly HOME_MODULE_ROUTE = '/';

  constructor(
    private readonly appConfigService: AppConfigService,
  ) {}

  private createAppModuleNamesMap(homeModuleName: string): ModuleNamesMap {
    return {
      [this.HOME_MODULE_ROUTE]: homeModuleName,
      ...this.getAppsModuleNamesMap(),
    };
  }

  private getAppsModuleNamesMap(): ModuleNamesMap {
    const moduleNamesMap: ModuleNamesMap = {};

    navigationStructure.forEach((item) => {
      moduleNamesMap[item.route] = item.label;
    });

    return moduleNamesMap;
  }
}
