import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { UserInfoFacade } from '@mp/shared/profil/data-access';
import { Observable, map } from 'rxjs';
import { NavigationGroup } from './navigation-item';

export function redirectToFirstChild(navigationGroup: NavigationGroup): CanActivateFn {
  return (): Observable<UrlTree> => {
    const userInfoFacade = inject(UserInfoFacade);
    const router = inject(Router);

    return userInfoFacade.profil$
      .pipe(
        map(() => {
          for (const child of navigationGroup.children) {
            if (child.permission == null || userInfoFacade.hasRecht(child.permission)) {
              return router.createUrlTree([navigationGroup.route, child.route]);
            }
          }

          return router.createUrlTree(['error']);
        }),
      );
  };
}
