import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { RechtFilter } from '@mp/shared/data-access';
import { UserInfoFacade } from '@mp/shared/profil/data-access';
import { Observable, map } from 'rxjs';

export function requirePermission(permission: RechtFilter): CanActivateFn {
  return (): Observable<boolean | UrlTree> => {
    const userInfoFacade = inject(UserInfoFacade);
    const router = inject(Router);

    return userInfoFacade.watchRecht$(permission, false)
      .pipe(
        map((hasPermission) => {
          return hasPermission || router.createUrlTree(['error']);
        }),
      );
  };
}
