<mp-root-module-nav
  *mpHeaderElement="'left'; let activeModuleName"
  [activeModuleName]="activeModuleName"
  [rootModuleLink]="['/']"
></mp-root-module-nav>

<div class="layout">
  <ng-container *ngIf="(profil$ | async) as profil">

    <h3 class="header">Willkommen {{ profil.vorname }} {{ profil.nachname }}!</h3>

    <mp-dashboard></mp-dashboard>

  </ng-container>
</div>
