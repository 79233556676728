import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, defaultIfEmpty, merge } from 'rxjs';
import { first, map, skip } from 'rxjs/operators';

import { filterUndefined } from '@core/shared/util';
import { Profil, UserInfoFacade } from '@mp/shared/profil/data-access';

@Injectable({ providedIn: 'root' })
export class ProfileVerifyService {
  private readonly profileInitialLoad$: Observable<Profil> = this.userInfoFacade.profil$;

  private readonly profileReload$: Observable<Profil> = this.userInfoFacade.profilChanges$.pipe(skip(1));

  constructor(private readonly router: Router, private readonly userInfoFacade: UserInfoFacade) {}

  verifyProfileConfig(): Observable<boolean> {
    this.userInfoFacade.loadProfile();

    // Also listen to profile reloads, because the profile might be reloaded if active organisation is selected automatically
    return merge(this.profileInitialLoad$, this.profileReload$).pipe(
      map((profile) => this.checkProfileConfiguration(profile)),
      filterUndefined(),
      defaultIfEmpty(false),
      first(),
    );
  }

  private checkProfileConfiguration(profile: Profil): boolean | undefined {
    if (profile.organisationen.length === 0) {
      this.redirectToPage('/no-organisation');
      return false;
    }

    if (this.userInfoFacade.hasOnlyOneAvailableOrganisation(profile)) {
      // Select the only available organisation automatically, which will also reload the profile and run profile verification again
      this.userInfoFacade.selectActiveOrganisationAutomatically(profile.activeOrganisationId);
      return undefined;
    }

    if (this.userInfoFacade.isOrganisationSelectionRequired(profile)) {
      this.redirectToPage('/active-organisation-selection');
      return false;
    }

    if (!this.userInfoFacade.hasRequiredRolesAndRights(profile)) {
      this.redirectToPage('/no-user-available');
      return false;
    }

    return true;
  }

  private redirectToPage(path: string): void {
    this.router.navigate([path], {replaceUrl: true});
  }
}
