import { Routes } from '@angular/router';

import { setupFeatureFlagsForModule } from '@core/shared/util';
import { provideOrganizationsDataAccess } from '@mp/kernel/organizations/data-access';
import { provideAddressesDataAccess } from '@mp/organization-master-data/addresses/data-access';
import { tabTitleResolver } from '@mp/shared/data-access';
import { provideFeedbackDataAccess } from '@mp/shared/helper-links/feedback/data-access';
import { provideHelpCenterDataAccess } from '@mp/shared/helper-links/help-center/data-access';
import { provideRolesDataAccess, provideUsersDataAccess } from '@mpk/shared/data-access';
import { provideCostCentersDataAccess } from '@mpomd/shared/data-access';

import { AppLayoutComponent } from './app-layout/app-layout.component';
import { LandingPageComponent } from './landing-page/landing-page.component';

export const routes: Routes = [
  {
    path: 'no-organisation',
    loadComponent: () => import('@mpe/shared').then((m) => m.NoOrganisationComponent),
  },
  {
    path: 'no-user-available',
    loadComponent: () => import('@mpe/shared').then((m) => m.NoUserAvailableComponent),
  },
  {
    path: 'active-organisation-selection',
    loadComponent: () =>
      import('@mp/system/active-organisation-selector/feature').then((m) => m.ActiveOrganisationSelectorComponent),
    title: tabTitleResolver('System | Organisation'),
  },
  {
    path: '',
    component: AppLayoutComponent,
    providers: [
      provideFeedbackDataAccess(),
      provideHelpCenterDataAccess(),
      provideOrganizationsDataAccess(),
      provideUsersDataAccess(),
      provideRolesDataAccess(),
      provideCostCentersDataAccess(),
      provideAddressesDataAccess(),
    ],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: LandingPageComponent,
        data: setupFeatureFlagsForModule({ customHeader: true }),
        title: tabTitleResolver('Startseite'),
      },
      {
        path: 'profil',
        data: setupFeatureFlagsForModule({ customHeader: true }),
        loadChildren: () => import('@mp/shared/profil/feature').then((m) => m.PROFILE_ROUTES),
      },

      /** Other routes are inserted here by route-builder during app init */
    ],
  },
];
