import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, map } from 'rxjs';

import { ViewModelSelector, filterUndefined } from '@core/shared/util';
import { FeedbackFacade } from '@mp/shared/helper-links/feedback/data-access';
import { FeedbackSettings, FeedbackType } from '@mp/shared/helper-links/feedback/domain';
import { MailSenderDetails } from '@mp/shared/helper-links/feedback/ui';
import { UserInfoFacade } from '@mp/shared/profil/data-access';

import { FeedbackButtonViewModel } from './feedback-button-view-model';

@Injectable()
export class FeedbackButtonStore extends ComponentStore<Record<string, never>> {
  readonly mailSenderDetails$: Observable<MailSenderDetails> = this.userInfoFacade.profil$.pipe(
    map(({ vorname, nachname, email }) => ({
      userFirstName: vorname,
      userLastName: nachname,
      userEmail: email,
    })),
  );

  readonly feedbackSettings$: Observable<FeedbackSettings> =
    this.feedbackFacade.feedbackSettings$.pipe(filterUndefined());

  readonly vm$: Observable<FeedbackButtonViewModel> = this.select(
    {
      mailSenderDetails: this.mailSenderDetails$,
      feedbackSettings: this.feedbackSettings$,
      isZendeskEnabled: this.feedbackSettings$.pipe(map(({ activeType }) => activeType === FeedbackType.Zendesk)),
    } satisfies ViewModelSelector<FeedbackButtonViewModel>,
    { debounce: true },
  );

  constructor(
    private readonly userInfoFacade: UserInfoFacade,
    private readonly feedbackFacade: FeedbackFacade,
  ) {
    super();
  }
}
