import { AsyncPipe, NgClass } from '@angular/common';
import { Component, HostBinding, Signal, WritableSignal, signal } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NavigationModule, ShellComponent } from '@core/ui';
import { AppConfigService } from '@mp/shared/data-access';
import { HelperLinksContainerComponent } from '@mp/shared/helper-links/feature';
import { ProfilDisplayComponent } from '@mp/shared/profil/feature';
import { NavigationGroup, NavigationItem, NavigationStructure, navigationStructure } from '../navigation';
import { UserInfoFacade } from '@mp/shared/profil/data-access';
import { toSignal } from '@angular/core/rxjs-interop';

// NOTE: Don't add the ChangeDetection.OnPush flag here! This leads to weird behavior of the submenu
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'mp-app-layout',
  standalone: true,
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss',
  imports: [
    NgClass,
    AsyncPipe,

    MatTooltipModule,

    ShellComponent,
    NavigationModule,
    HelperLinksContainerComponent,
    ProfilDisplayComponent,
    MatDividerModule,
  ],
})
export class AppLayoutComponent {
  @HostBinding('class') readonly class = 'mp-app-layout';

  hasHelperFunctions = false;

  readonly isSideNavOpened: WritableSignal<boolean> = signal<boolean>(false);

  readonly appTitle$: Observable<string> = this.appConfigService.envConfig$.pipe(
    map((envConfig) => envConfig.appTitle),
  );

  readonly showAppLogo$: Observable<boolean | undefined> = this.appConfigService.envConfig$.pipe(
    map((envConfig) => envConfig.showAppLogo),
  );

  protected readonly navigationStructure: Signal<NavigationStructure> = toSignal(
    this.userInfoFacade.profil$.pipe(
      map(() => this.buildNavigationStructure()),
    ),
    {
      initialValue: [],
    });

  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly userInfoFacade: UserInfoFacade,
  ) {}

  private buildNavigationStructure(): NavigationStructure {
    const result: NavigationStructure = [];

    for (const item of navigationStructure) {
      // Group item
      if ('children' in item) {
        const group: NavigationGroup = {
          ...item,
          children: [],
        };

        for (const child of item.children) {
          if (this.navigationItemIsVisible(child)) {
            group.children.push(child);
          }
        }

        if (group.children.length > 0) {
          result.push(group);
        }

      // Single item
      } else {
        if (this.navigationItemIsVisible(item)) {
          result.push(item);
        }
      }
    }

    return result;
  }

  private navigationItemIsVisible(navigationItem: NavigationItem): boolean {
    if (navigationItem.permission != null) {
      if (!this.userInfoFacade.hasRecht(navigationItem.permission)) {
        return false;
      }
    }

    return true;
  }
}
