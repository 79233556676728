import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HeaderElementDirective } from '@core/shared/util';
import { RootModuleNavComponent } from '@core/ui';
import { DashboardComponent } from '@mp/dashboard/feature';
import { UserInfoFacade } from '@mp/shared/profil/data-access';

@Component({
  selector: 'mp-landing-page',
  standalone: true,
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, AsyncPipe, DashboardComponent, HeaderElementDirective, RootModuleNavComponent],
})
export class LandingPageComponent {
  @HostBinding('class') readonly classes = 'mp-landing-page';

  readonly profil$: Observable<{ vorname: string; nachname: string }> = this.userInfoFacade.profil$.pipe(
    map(({ vorname, nachname }) => ({ vorname, nachname })),
  );

  constructor(private readonly userInfoFacade: UserInfoFacade) {}
}
