import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';

import { ErrorComponent } from '@core/ui';

import { RouteBuilder } from './route-builder';

import { navigationStructure } from './navigation-structure';

@Injectable({ providedIn: 'root' })
export class NavigationRouteBuilderService {
  constructor(private readonly router: Router) {}

  initRoutes(): void {
    const navigationStructureRoutes = this.buildRoutesFromNavigationStructure(this.router.config);
    this.router.resetConfig(navigationStructureRoutes);
  }

  private buildRoutesFromNavigationStructure(initialRouterConfig: Route[]): Route[] {
    const errorRoute = {
      path: 'error',
      component: ErrorComponent,
      data: {
        errorCode: 404,
        errorMessage: 'Die angegebene Seite konnte nicht gefunden werden.',
      },
    };

    const homeRouteConfig: Route = this.findHomeRoute(initialRouterConfig);
    homeRouteConfig.children = RouteBuilder.fromRouterConfig(homeRouteConfig.children ?? [])
      .registerNavigation(navigationStructure)
      .registerErrorRoute(errorRoute)
      .buildRoutes();

    return initialRouterConfig;
  }

  private findHomeRoute(initialRoutes: Route[]): Route {
    return initialRoutes.find((route) => route.path === '') as Route;
  }
}
